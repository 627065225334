<template>
  <section class="daily-template">
    <section class="daily-template-top">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="日报标题：" prop="reportName">
          <el-input v-model="form.reportName" style="width: 282px"></el-input>
        </el-form-item>
        <el-form-item label="Logo展示：" prop="isTSLogo">
          <el-radio-group
            v-model="form.isTSLogo"
          >
            <el-radio
              border
              label="1"
            >是</el-radio>
            <el-radio
              border
              label="2"
            >否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客户Logo上传：" v-if="form.isTSLogo === '1'">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :action="uploadImageURL"
            name="file">
            <img v-if="logoImageURL" :src="logoImageURL" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div>
            <span slot="tip" class="el-upload__tip">( 提示: 图片只能上传jpg / jpeg / png格式，且最大不超过2MB )</span>
          </div>
        </el-form-item>
        <el-form-item label="模板选择：" prop="themeTemplate">
          <el-radio-group v-model="form.themeTemplate" @change="tempChange">
            <el-radio
              border
              label="1"
            >模板一</el-radio>
            <el-radio
              border
              label="2"
            >模板二</el-radio>
            <el-radio
              border
              label="3"
            >模板三</el-radio>
             <el-radio
              border
              label="4"
            >模板四</el-radio>
          </el-radio-group>
        </el-form-item>

<!--        <el-form-item v-show="monitorSubject"  label="主题选择：" prop="monitorSubjectId">-->
<!--          <el-select v-model="form.monitorSubjectId" placeholder="请选择主题" value-key="id" >-->
<!--            <el-option   v-for = "item in list" :key="item.id" :label = "item.typeName" :value="item.id">{{item.typeName}}</el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label-width="40px">
          <span class="colorSetting">
            <b>主题颜色：</b>
            <el-input type="text" v-model="form.themeColor" ></el-input>
            <el-color-picker v-model="form.themeColor" size="mini"></el-color-picker>
          </span>
          <span class="colorSetting">
            <b>字体颜色：</b>
            <el-input type="text" v-model="form.fontColor"></el-input>
            <el-color-picker v-model="form.fontColor" size="mini"></el-color-picker>
          </span>
        </el-form-item>
        <el-form-item label-width="40px">
          <el-button
            type="primary"
            @click="onSubmit"
          >保存</el-button>
          <el-button v-if="flag" style="margin-left: 30px;" @click="returnBefore">返回</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="daily-template-bottom">
      <h2 style="text-align: center;">日报示例</h2>
      <img :src="tempImageURL" class="templateImg" alt="加载失败">
    </section>
  </section>
</template>

<script>
import { apiBaseURL } from '@/utils/request'
import { getToken } from '@/utils/auth'
import { saveTemplate, selectTemplate } from '@/api/daily/template'
import { getMonitorSubjectByCid } from '@/api/daily/report'
export default {
  data () {
    return {
      monitorSubject: false,
      flag: !!this.$route.query.flag,
      form: {
        reportName: '',
        isTSLogo: '',
        themeTemplate: '1',
        themeColor: '#409EFF',
        fontColor: '#409EFF',
        logo: '',
        monitorSubjectId: ''
      },
      list: [],
      uploadImageURL: '',
      logoImageURL: '',
      tempImageURL: require('@/assets/images/template1.jpg')
    }
  },
  methods: {
    tempChange () {
      if( this.form.themeTemplate === '1' ) {
        this.monitorSubject=false
        this.form.monitorSubjectId=null
        this.tempImageURL = require('@/assets/images/template1.jpg')
      } else if( this.form.themeTemplate === '2' ) {
        this.monitorSubject=false
        this.form.monitorSubjectId=null
        this.tempImageURL = require('@/assets/images/template2.jpg')
      } else if(this.form.themeTemplate === '4' ){
        this.monitorSubject = true
        this.tempImageURL = require('@/assets/images/template4.png')
      }else {
        this.form.monitorSubjectId=null
        this.monitorSubject=false
        this.tempImageURL = require('@/assets/images/template3.jpg')
      }
    },
    // 图片上传之前调用
    beforeUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 图片上传成功后调用
    uploadSuccess (res, file) {
      this.form.logo = res.msg
      this.logoImageURL = URL.createObjectURL(file.raw)
      this.$message.success({ showClose: true, message: '上传成功', center: true, duration: 3000 })
    },
    returnBefore () {
      this.$router.back()
    },
    async getMonitorSubjectByCidView () {
      const res = await getMonitorSubjectByCid()
      console.log(res)
      this.list = res.data
    },
    async onSubmit () {
      console.log(this.form, '122')
      this.$emit('visible',false)

      await saveTemplate(this.form)
      this.$message.success({ showClose: true, message: '操作成功', center: true, duration: 2000 })
      this.$refs['form'].resetFields()
    },
    async initializeSelect () {
      const res = await selectTemplate()

      if(res.data.themeTemplate.toString() =='4'){
          this.monitorSubject=true
      }else {
        this.monitorSubject=false
      }
      this.form.reportName = res.data.reportName
      this.form.isTSLogo = res.data.isTSLogo.toString()
      this.form.themeTemplate = res.data.themeTemplate.toString()
      this.form.themeColor = res.data.themeColor
      this.form.fontColor = res.data.fontColor
      this.form.logo = res.data.logo
      this.form.monitorSubjectId = res.data.monitorSubjectId
      if( this.form.themeTemplate === '1' ) {
        this.monitorSubject=false
        this.form.monitorSubjectId=null
        this.tempImageURL = require('@/assets/images/template1.jpg')
      } else if( this.form.themeTemplate === '2' ) {
        this.monitorSubject=false
        this.form.monitorSubjectId=null
        this.tempImageURL = require('@/assets/images/template2.jpg')
      } else if(this.form.themeTemplate === '4' ){
        this.monitorSubject = true
        this.tempImageURL = require('@/assets/images/template4.png')
      }else {
        this.form.monitorSubjectId=null
        this.monitorSubject=false
        this.tempImageURL = require('@/assets/images/template3.jpg')
      }
    }
  },
  mounted () {
    this.getMonitorSubjectByCidView()
    this.initializeSelect()
    let token = getToken()
    this.uploadImageURL = apiBaseURL + '/webuploader/reportTemplateUploadImages?token=' + token
  }
}
</script>

<style lang="scss">
.daily-template {
  width: 100%;
  height: 100%;
  .daily-template-top{
    position: relative;
    border-radius: 10px;
    left: 10px;
    border: 1px #eceaea solid;
    background: #ffffff;
    box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
    .el-form{
      margin-top: 30px;
      .colorSetting{
        color:#606266;
        margin-right: 40px;
        .el-input{
          width: 100px;
        }
        .el-color-picker--mini{
          top: 10px;
          left: -30px;
        }
      }
      .el-radio__input{
        display: none;
      }
      .el-radio--mini.is-bordered{
        width: 70px;
        text-align: center;
        padding: 6px 10px 0px 0px;
        margin-right: 10px;
      }
      .upload-demo{
        float: left;
      }
      .el-upload__tip{
        margin-left: 20px;
      }
      .el-button--mini{
        padding: 7px 5px;
        width: 70px;
      }
    }
  }
  .daily-template-bottom{
    position: relative;
    top: 30px;
    left: 10px;
    border-radius: 10px;
    border: 1px #eceaea solid;
    background: #ffffff;
    box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
    .templateImg{
      width: 100%;
    }
  }
}
</style>
