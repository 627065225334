<template>
  <section class="daily-data">
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      size="mini"
    >
      <el-form-item label="日报文件名称：" prop="name">
        <el-input v-model="form.name" style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="生成时间：" prop="date">
<!--        <el-date-picker-->
<!--          v-model="form.date"-->
<!--          type="date"-->
<!--          placeholder="选择时间"-->
<!--          value-format="yyyy-MM-dd">-->
<!--        </el-date-picker>-->
        <el-date-picker
          v-model="value1"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions1"
          :editable="false"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="情感类型：" prop="emotionType">
        <el-checkbox-group v-model="form.emotionType">
          <el-checkbox
            label="0"
            name="emotionType"
          >正面</el-checkbox>
          <el-checkbox
            label="1"
            name="emotionType"
          >负面</el-checkbox>
          <el-checkbox
            label="2"
            name="emotionType"
          >敏感</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="媒体类型：" prop="mediaType">
        <el-checkbox-group v-model="form.mediaType">
            <el-checkbox v-for="item in siteTypeList" :label="item.id" :name="item.siteName" :key="item.id">{{ item.siteName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item label="日报模板：">
        <el-radio-group v-model="form.dailyTemplate">
          <el-radio label="1">模板一</el-radio>
          <el-radio label="2">模板二</el-radio>
          <el-radio label="3">模板三</el-radio>
        </el-radio-group>
        <el-button type="primary" size="mini" @click="dailyTemplate">更换</el-button>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          @click="preview"
        >确认生成</el-button>
        <!-- 点击生成按钮跳转到预览页面，点击下载push到日报列表 -->
        <!-- <el-button @click="preview">预览</el-button> -->
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { listAllSiteType } from '@/api/daily/report'
import { creatDaily, previewDaily, folderReport } from '@/api/daily/data'
import { apiBaseURL } from '@/utils/request'
import { getToken } from '@/utils/auth'
export default {
  data () {
    return {
      value1: '', // 日
      data1Map: new Map(),
      data2Map: new Map(),
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 31 * 24 * 3600 * 1000,
      // 日期使用
      pickerOptions1: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (time.getTime() > (this.pickerMinDate + this.day31)) || (time.getTime() < (this.pickerMinDate - this.day31));
          }
          return false;
        }
      },
      siteTypeList: [],
      form: {
        ffid: localStorage.getItem("ffid"),
        name: '',
        date: '',
        emotionType: ['0', '1', '2'],
        mediaType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        dailyTemplate: '',
        startDate: '',
        endDate: ''
      },
      data: {
        templateName: '2020.01.01舆情监测日报',
        createDate: '2020.01.01',
        summary: '今日共监测到10篇新闻。其中百度5篇，腾讯5篇。',
        themeList: [
          {
            id: '001',
            name: '监测主题1',
            count: 2,
            news: [{
              name: '普通新闻1'
            }, {
              name: '普通新闻2'
            }],
            newsGroup: [
              {
                id: 1,
                name: '新闻组1',
                count: 2,
                childNews: [
                  {
                    id: 11,
                    name: '子新闻11'
                  },
                  {
                    id: 12,
                    name: '子新闻12'
                  }
                ]
              },
              {
                id: 2,
                name: '新闻组2',
                count: 2,
                childNews: [
                  {
                    id: 21,
                    name: '子新闻21'
                  },
                  {
                    id: 22,
                    name: '子新闻22'
                  }
                ]
              }
            ]
          },
          {
            id: '002',
            name: '监测主题2',
            count: 4,
            news: [{ name: '普通新闻1' }, { name: '普通新闻2' }],
            newsGroup: [
              {
                id: 10,
                name: '新闻组10',
                count: 4,
                childNews: [
                  {
                    id: 110,
                    name: '子新闻110'
                  },
                  {
                    id: 120,
                    name: '子新闻120'
                  }
                ]
              },
              {
                id: 20,
                name: '新闻组20',
                count: 4,
                childNews: [
                  {
                    id: 210,
                    name: '子新闻210'
                  },
                  {
                    id: 22,
                    name: '子新闻22'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    async listSiteType () {
      const res = await listAllSiteType()
      this.siteTypeList = res.data
    },
    async onSubmit () {
      try {
        await creatDaily(this.form)
        this.$message.success('日报已生成!')
      } catch (error) {
        this.$message.error('服务器内部异常')
      }
    },
    // 预览
    async preview () {
      console.log(this.form)
      this.form.startDate = this.value1[0]
      this.form.endDate = this.value1[1]
      if (this.form.name === '') {
        this.$message.error('日报名称为必填项')
      }
      if (this.value1 === '') {
        this.$message.error('日报生成时间为必填项')
      }
      if (this.form.emotionType.length === 0) {
        this.$message.error('请至少选择一个情感类型')
      }
      if (this.form.mediaType.length === 0) {
        this.$message.error('请至少选择一个媒体类型')
      }
      if (this.form.name !== '' && this.value1 !== '' && this.form.emotionType.length > 0 && this.form.mediaType.length > 0) {
        this.$emit('visible', false)
        let data = JSON.stringify(this.form)
        var url = apiBaseURL + '/favorite_folder/exportReport?token='+ getToken() +'&ffid='+this.form.ffid+'&name='+this.form.name+'&startDate='+this.form.startDate+'&endDate='+this.form.endDate+'&emotionType='+this.form.emotionType+'&mediaType='+this.form.mediaType
        window.open(url, '_blank')
      }
      /* try {
        const res = await previewDaily(this.form)
        console.log('dailyData:' + JSON.stringify(res))
        this.$router.push({ path: 'dailyPreview', query: { data: res } })
      } catch (e) {
        this.$message.error('服务器内部异常')
      } */
      // this.$refs['form'].resetFields()
    },
    dailyTemplate () {
      this.$router.push({ path: 'dailyTemplate', query: { flag: true } })
    }
  },
  mounted () {
    this.listSiteType()
  }
}
</script>

<style lang="scss">
.daily-data {
  width: 99%;
  height: 100%;
  border-radius: 10px;
  margin-left: 20px;
  border: 1px #eceaea solid;
  background: #ffffff;
  box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
  .el-form{
    margin-left: 20px;
    margin-top: 40px;
    .el-checkbox{
      margin-right: 15px;
    }
    .el-radio{
      margin-right: 15px;
    }
    .el-form-item--mini.el-form-item:last-child{
      margin-left: -80px;
    }
  }
}
</style>
