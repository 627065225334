import request from '@/utils/request'

export function creatDaily (data) {
  return request({
    url: '/report/creatDaily',
    method: 'POST',
    data: data
  })
}

export function previewDaily (data) {
  return request({
    url: '/report/daily',
    method: 'POST',
    data: data
  })
}

export function folderReport (data) {
  return request({
    url: '/favorite_folder/exportReport',
    method: 'GET',
    data: data
  })
}
