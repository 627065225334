import request from '@/utils/request'

export function saveTemplate (data) {
  return request({
    url: '/report/saveTemplate',
    method: 'POST',
    data: data
  })
}

export function selectTemplate () {
  return request({
    url: '/report/selTemplatebycid',
    method: 'POST'
  })
}
