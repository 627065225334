import request from '@/utils/request'

export function selectDailyByDate (data) {
  return request({
    url: '/report/findDailyLogByCondition',
    method: 'POST',
    data: data
  })
}
export function deleteDaily (data) {
  return request({
    url: '/report/deleteDailyLog',
    method: 'POST',
    data: data
  })
}

export function pushDaily (data) {
  return request({
    url: '/report/pushDaily',
    method: 'POST',
    data: data
  })
}

export function pushDailyToEamil (data) {
  return request({
    url: '/report/sendReportEmail',
    method: 'POST',
    data: data
  })
}
export function creatDaily (data) {
  return request({
    url: '/report/creatDaily',
    method: 'POST',
    data: data
  })
}
export function viewDaily (data) {
  return request({
    url: '/report/view',
    method: 'POST',
    data: data
  })
}
export function listAllSiteType (data) {
  return request({
    url: '/data/listSiteType',
    method: 'POST',
    data
  })
}

export function getMonitorSubjectByCid (data) {
  return request({
    url: '/report/getMonitorSubjectByCid',
    method: 'POST',
    data: data
  })
}
